import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '../auth/services/authService';
import { useAuthContext } from '../auth';

const IDLE_TIMEOUT = 60 * 60 * 1000; // 60 minutes timeout

export const IdleTimeoutHandler = () => {
  const { authorized } = useAuthContext();
  const navigate = useNavigate();
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleUserLogout = useCallback(() => {
    // log them out
    authService.logout();
    navigate('/auth');
    alert('You have been logged out due to inactivity.');
  }, [navigate]);

  const resetTimeout = useCallback(() => {
    // Clear the existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a new timeout
    timeoutRef.current = setTimeout(() => {
      if (authorized) {
        handleUserLogout();
      }
    }, IDLE_TIMEOUT);
  }, [authorized, handleUserLogout]);

  useEffect(() => {
    // Event listener to detect user activity
    const idleEvents = ['mousemove', 'keydown', 'click'];
    idleEvents.forEach((event) => window.addEventListener(event, resetTimeout));

    // Initial timeout setup
    resetTimeout();

    return () => {
      // Cleanup event listeners and timeout
      idleEvents.forEach((event) =>
        window.removeEventListener(event, resetTimeout)
      );
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [resetTimeout]);

  return null;
};
