import {
  MenuItem,
  SelectChangeEvent,
  Typography,
  Grid,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { FC, useEffect, useState, ChangeEvent } from 'react';
import { ScanModel } from '../../network';
import { MeshViewer } from './MeshViewer';
import {
  patchSuperRecord,
  retriggerScan,
  patchFeatureSet,
} from '../../../allScans/Network';
import { enqueueSnackbar } from 'notistack';
import {
  EmptyView,
  IdBadge,
  PositionedStack,
  ViewModeSelect,
  Wrapper,
} from './styles';
import { PreloadUrl } from './MeshViewer/types';
import { useScanCompareContext } from '../ScanCompare/useScanCompareContext';
import { MeshType, ViewModeLabel } from './MeshViewer/layers';
import { useScanLayerControls } from '../../hooks/useScanLayerControls';
import { ChangeRecordStatus } from '../ChangeRecordStatus';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export interface ScanItemViewerProps {
  item: ScanModel;
  name?: 'first' | 'second';
  nextItems?: PreloadUrl[] | undefined;
  setIncomingPatchRequest?: (value: boolean) => void;
  setLoadTable?: (value: boolean) => void;
  loading?: boolean;
}

export const ScanItemViewer: FC<ScanItemViewerProps> = (props) => {
  const {
    meshType: syncedMeshType,
    synced,
    splitMode,
    setMeshType: setSyncedMeshType,
  } = useScanCompareContext();
  const { meshType, setMeshType } = useScanLayerControls();

  const localMeshType = splitMode && synced ? syncedMeshType : meshType;
  const localOnChange = splitMode && synced ? setSyncedMeshType : setMeshType;

  const { item, nextItems, loading, setIncomingPatchRequest, setLoadTable } =
    props;
  const location = useLocation();
  const [viewModeOptions, setViewModeOptions] = useState<Array<MeshType>>([]);

  const [excludeFromTraining, setExcludeFromTraining] = useState<boolean>(
    item.excluded_from_training || false
  );

  const [clearedForRelease, setClearedForRelease] = useState<boolean>(
    item.cleared_for_release || false
  );
  const [patchCallFired, setPatchCallFired] = useState<boolean>(false);

  const onChangeViewMode = (event: SelectChangeEvent<unknown>) => {
    localOnChange(event.target.value as MeshType);
  };

  useEffect(() => {
    if (
      item &&
      item.excluded_from_training !== undefined &&
      item.status !== undefined &&
      item.cleared_for_release !== undefined
    ) {
      setExcludeFromTraining(item.excluded_from_training);
      setClearedForRelease(item.cleared_for_release);
    }
  }, [item]);

  useEffect(() => {
    if (item) {
      const result: Array<MeshType> = [];
      if (item.bent_mesh_stream) {
        result.push('bent');
      }
      if (item.standing_mesh_stream) {
        result.push('standing');
      }

      setViewModeOptions(result);
      localOnChange((state) => {
        if (state === null && result.length > 0) {
          return result[0];
        }
        if (state !== null && result.length > 0) {
          if (result.includes(state)) {
            return state;
          }
          return result[0];
        }
        return null;
      });
    } else {
      setViewModeOptions([]);
      localOnChange(null);
    }
  }, [item, location.pathname, localOnChange]);

  const handlePatchOperation = async (
    field: 'excluded_from_training' | 'cleared_for_release' | 'status',
    itemId: number | undefined | null,
    newChecked: boolean,
    setFieldState: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (itemId === undefined || itemId === null) return;

    setPatchCallFired(true);
    setFieldState(newChecked);
    setLoadTable && setLoadTable(true);

    let patchValue: boolean | string = newChecked;

    if (field === 'status') {
      patchValue = newChecked ? 'Failed' : 'Completed';
    }

    try {
      const patchFunction =
        field === 'excluded_from_training' || field === 'status'
          ? patchSuperRecord
          : patchFeatureSet;

      await patchFunction(itemId, { [field]: patchValue });
      setIncomingPatchRequest && setIncomingPatchRequest(true);
    } catch (e: unknown) {
      console.error('Error updating record:', e);
      setLoadTable && setLoadTable(false);
    }

    setTimeout(() => {
      setPatchCallFired(false);
    }, 2000);
  };

  const handleCheckboxChange = async (
    event: ChangeEvent<HTMLInputElement>,
    field: 'excluded_from_training' | 'status' | 'cleared_for_release',
    id: number | undefined | null,
    setStateCallback: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    handlePatchOperation(field, id, event.target.checked, setStateCallback);
  };

  const retriggerProcessing = async () => {
    try {
      const response = await retriggerScan({ id: item.id });
      enqueueSnackbar(response['Success'], { variant: 'success' });
    } catch (e: any) {
      console.error('Error retriggering record:', e);
    }
  };

  const shouldHideModeSelect = splitMode === true && synced === true;

  const allScansInfoViewer = (item: ScanModel) => {
    return (
      <Accordion
        style={{
          width: '220px',
          background: 'none',
          boxShadow: 'none',
        }}
      >
        <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
          <div>
            <Typography variant='subtitle2' textAlign='left'>
              Record ID: {item.id}
            </Typography>
            <Typography variant='subtitle2' textAlign='left'>
              FeatureSet ID: {item.most_recent_featureset_id}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0, margin: 0 }}>
          <Grid
            container
            alignItems='flex-start'
            direction='column'
            width='100%'
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ padding: '4px 11px', marginLeft: '0.3vw' }}
                    checked={excludeFromTraining}
                    onChange={(e) =>
                      handleCheckboxChange(
                        e,
                        'excluded_from_training',
                        item.id,
                        setExcludeFromTraining
                      )
                    }
                    disabled={loading || patchCallFired}
                    sx={{
                      '&, & + .MuiFormControlLabel-label': {
                        color: '#353535',
                        fontWeight: '600',
                        marginLeft: '-0.25vw',
                      },
                    }}
                  />
                }
                label='Excluded from training'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ padding: '4px 11px', marginLeft: '0.3vw' }}
                    checked={clearedForRelease}
                    onChange={(e) =>
                      handleCheckboxChange(
                        e,
                        'cleared_for_release',
                        item.most_recent_featureset_id,
                        setClearedForRelease
                      )
                    }
                    disabled={
                      loading ||
                      patchCallFired ||
                      item.most_recent_featureset_id === null
                    }
                    sx={{
                      '&, & + .MuiFormControlLabel-label': {
                        color: '#353535',
                        fontWeight: '600',
                        marginLeft: '-0.25vw',
                      },
                    }}
                  />
                }
                label='Cleared for release'
              />
            </FormGroup>
            <Grid container padding='8px'>
              <ChangeRecordStatus
                item={item}
                setIncomingPatchRequest={setIncomingPatchRequest}
              />
              <Button
                type='submit'
                variant='outlined'
                size='small'
                fullWidth
                style={{ marginTop: '8px' }}
                onClick={retriggerProcessing}
              >
                Retrigger Processing
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <Wrapper darkBackgroundColor='#353535'>
      {viewModeOptions.length > 0 && shouldHideModeSelect === false && (
        <ViewModeSelect
          value={localMeshType}
          onChange={onChangeViewMode}
          variant='filled'
          disableUnderline
        >
          {viewModeOptions.map((x, index) => {
            if (x === null) {
              return null;
            }

            const text = ViewModeLabel[x];

            return text ? (
              <MenuItem key={`${text}-${index}`} value={x}>
                {text}
              </MenuItem>
            ) : null;
          })}
        </ViewModeSelect>
      )}
      {localMeshType === null && (
        <EmptyView>
          <Typography variant='subtitle1'>Nothing to show</Typography>
        </EmptyView>
      )}
      {localMeshType === 'bent' && (
        <>
          {item.bent_mesh_stream && (
            <MeshViewer
              name={props.name}
              mesh_url={item.bent_mesh_stream}
              deviation_mesh_url={
                item.featureset_values?.[0]?.bent_deviation_mesh_stream ?? null
              }
              mesh_type='bent'
              mesh_texture_url={item?.bent_mesh_texture_stream ?? null}
              mesh_metadata={item.featureset_values?.[0]?.mesh_metadata ?? null}
              plumb_shift={null}
              shoulder_asymmetry={null}
              trunk_shift={null}
              preloadUrls={nextItems || []}
            />
          )}
        </>
      )}
      {localMeshType === 'standing' && (
        <>
          {item.standing_mesh_stream && (
            <MeshViewer
              name={props.name}
              mesh_url={item.standing_mesh_stream}
              deviation_mesh_url={null}
              mesh_type='standing'
              mesh_texture_url={item?.standing_mesh_texture_stream ?? null}
              mesh_metadata={item.featureset_values?.[0]?.mesh_metadata ?? null}
              plumb_shift={item.featureset_values?.[0]?.plumb_shift ?? null}
              shoulder_asymmetry={
                item.featureset_values?.[0]?.shoulder_asymmetry ?? null
              }
              trunk_shift={item.featureset_values?.[0]?.trunk_shift ?? null}
              preloadUrls={nextItems || []}
            />
          )}
        </>
      )}

      <PositionedStack
        position='tl'
        variant={location.pathname === '/all-scans' ? 'custom' : 'default'}
      >
        {location.pathname === '/all-scans' ? (
          allScansInfoViewer(item)
        ) : (
          <IdBadge>{item.id}</IdBadge>
        )}
      </PositionedStack>
      {item.predicted_cobb && (
        <PositionedStack position='bl'>
          <IdBadge>AI Predicted Cobb Angle: {item.predicted_cobb}&deg;</IdBadge>
        </PositionedStack>
      )}
    </Wrapper>
  );
};
