import { Button } from '@mui/material';
import { ScanModel } from '../../network';
import { FC, useEffect, useState } from 'react';
import {
  FormStackHorizontal,
  FormStackVertical,
  Modal,
  TextInputFF,
} from '../../../ui';
import { Form } from 'react-final-form';
import { ValidationErrors } from 'final-form';
import { patchSuperRecord } from '../../../allScans/Network';
import { enqueueSnackbar } from 'notistack';

interface ChangeRecordStatusProps {
  item: ScanModel;
  setIncomingPatchRequest: ((value: boolean) => void) | undefined;
}

export enum Status {
  Completed = 'Completed',
  Failed = 'Failed',
}

export const ChangeRecordStatus: FC<ChangeRecordStatusProps> = ({
  item,
  setIncomingPatchRequest,
}) => {
  const language = item.patient_language;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<string | undefined | null>(item.status);

  useEffect(() => {
    setStatus(item.status);
  }, [item]);

  const handleClick = async () => {
    setModalOpen(true);
  };

  const onSubmitFailedStatus = async (values: any) => {
    const userId = item.id;

    const payload = {
      status: Status.Failed,
      failure_reason: values.failure_reason,
      suggestion: values.suggestion,
    };

    try {
      const result = await patchSuperRecord(userId, payload);
      setIncomingPatchRequest && setIncomingPatchRequest(true);
      setStatus(result.status);
      enqueueSnackbar('Record marked as failed', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to mark record as failed', { variant: 'error' });
      console.error(error);
    } finally {
      setModalOpen(false);
    }
  };

  const onSubmitCompletedStatus = async () => {
    const userId = item.id;
    const payload = {
      status: Status.Completed,
    };

    try {
      const result = await patchSuperRecord(userId, payload);
      setIncomingPatchRequest && setIncomingPatchRequest(true);
      setStatus(result.status);
      enqueueSnackbar('Record marked as completed', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to mark record as completed', {
        variant: 'error',
      });
      console.error(error);
    } finally {
      setModalOpen(false);
    }
  };

  const validate = (values: any) => {
    const result: ValidationErrors = {};

    if (!values.failure_reason) {
      result.failure_reason = true;
    }
    if (!values.suggestion) {
      result.suggestion = true;
    }

    return result;
  };

  const buttonText =
    status === Status.Completed
      ? 'Mark Record as Failed'
      : 'Mark Record as Completed';

  return (
    <>
      <Button onClick={handleClick} variant='outlined' size='small' fullWidth>
        {buttonText}
      </Button>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        {status === Status.Completed ? (
          <>
            <h1>Mark record as failed</h1>
            <h4>
              User's preferred language: <b>{language}</b>
            </h4>
            <Form
              onSubmit={onSubmitFailedStatus}
              validate={validate}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <FormStackVertical>
                    <TextInputFF
                      name='failure_reason'
                      label='Failure Reason'
                      fullWidth
                    />
                    <TextInputFF
                      name='suggestion'
                      label='Suggestion'
                      multiline
                      rows={4}
                      fullWidth
                    />
                    <Button type='submit'>Submit</Button>
                  </FormStackVertical>
                </form>
              )}
            />
          </>
        ) : (
          <>
            <h1>Mark record as Completed?</h1>
            <h4>Are you sure you want to mark as completed?</h4>
            <FormStackHorizontal>
              <Button onClick={onSubmitCompletedStatus}>Yes</Button>
              <Button
                variant='containedLight'
                onClick={() => setModalOpen(false)}
              >
                Cancel
              </Button>
            </FormStackHorizontal>
          </>
        )}
      </Modal>
    </>
  );
};
