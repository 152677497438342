import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router } from 'react-router-dom';
import { RootLayout } from '../../components/RootLayout';
import { AuthProvider } from '../../modules/auth';
import { mainTheme } from '../../theme/mainTheme';
import { NavigationContainer } from '../NavigationContainer';
import { LicenseInfo } from '@mui/x-license-pro';
import { IdleTimeoutHandler } from '../../modules/sessionTimeout';

export const RootContainer = () => {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY || '');
  return (
    <ThemeProvider theme={mainTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Router>
          <RootLayout>
            <AuthProvider>
              <NavigationContainer />
              <IdleTimeoutHandler />
            </AuthProvider>
          </RootLayout>
        </Router>
      </LocalizationProvider>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      />
    </ThemeProvider>
  );
};
